import isEmail from 'validator/es/lib/isEmail';
import isURL from 'validator/es/lib/isURL';

export const useValidation = () => {

    const validateEmail = (email: string) => {
        if (email !== '' && isEmail(email)) { return true; }
        return false;
    }
    const validateURL = (url: string) => {
        if (url !== '' && isURL(url, { require_protocol: true })) { return true; }
        return false;
    }
    const validateFacebookURL = (url: string) => {
        const pattern = /^(?:(?:http|https):\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i
        if (pattern.test(url)) return true
        else return false
    }
    const validateInstagramURL = (url: string) => {
        const pattern = /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)/igm
        if (pattern.test(url)) return true
        else return false
    }
    const validateTwitterURL = (url: string) => {
        const pattern = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/i
        if (pattern.test(url)) return true
        else return false
    }
    const validateDate = (d: Date) => {
        return !isNaN(d.getTime());
    }

    return {
        validateEmail,
        validateURL,
        validateFacebookURL,
        validateInstagramURL,
        validateTwitterURL,
        validateDate
    }
}